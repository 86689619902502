import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Spinner, Alert } from 'react-bootstrap'
import Fetch from '../../helpers/fetch';
import constants from '../../helpers/constants';

const SupplierBilling = ({billingInfo}) => {
  const [lineItems, setLineItems] = useState([]);
  const [billOptions, setBillOptions] = useState([])
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleOnChange = (e) => {
    const type = e.target.dataset.type;
    const existing = [...lineItems];
    const objectAtIndex = existing[e.target.dataset.index];

    switch (type) {
      case 'type':
        objectAtIndex.label = e.target.value;
        const billOption =  billOptions.find(billOption => billOption.label.trim() === e.target.value.trim());
        objectAtIndex.cost = Number(billOption.cost);
        break;
      case 'cost':
        objectAtIndex.cost = e.target.value ? Number(e.target.value) : '';
        break;
      default:
        break;
    }
    setLineItems(existing);
  }

  const handleAddLine = (e) => {
    const newLine = {
      label: 'Custom Design',
      cost: null,
      new: true,
    }
    if (!lineItems) {
      setLineItems([newLine])
    } else {
      setLineItems([...lineItems, newLine]);
    }
  }

  const handleOnDeleteClick = (e) => {
    const existing = [...lineItems];
    const index = e.target.dataset.index;
    if (existing[index] && existing[index].fromDB) {
      Fetch.delete(`${constants.BACKEND_URL}/suppliers-bill/${existing[index].id}`)
      .then(() => {

      })

    }
    existing.splice(index,1);
    setLineItems(existing);
  }

  const handleSave = (e) => {
    const data = {
      custId: billingInfo.custId,
      supplierBill: lineItems
    }
    
    Fetch.put(`${constants.BACKEND_URL}/suppliers-bill/suppliers/${billingInfo.supplierId}/orders/${billingInfo.orderId}/items/${billingInfo.orderItemId}`, JSON.stringify(data))
      .then((data) => {
        if (data && data.length > 0) {
          const newBill = data.map(x => {
            x.fromDB = true
            return x
          })
          setLineItems(newBill)
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 3000);
        } 
      })
      .catch(e => {
      })
    
  }

  const getTotal = () => {
    let subTotal = 0 ;
    if (lineItems && lineItems.length > 0) {
      lineItems.forEach(item => {
        subTotal += item.cost
      })
    }
    return subTotal;
  }

  const getSupplierBill = (orderItemId) => {
    Fetch.get(`${constants.BACKEND_URL}/suppliers-bill/suppliers/${billingInfo.supplierId}/orders/${billingInfo.orderId}/items/${orderItemId}`)
      .then(bill => {
        Fetch.get(`${constants.BACKEND_URL}/suppliers-cost/suppliers/${billingInfo.supplierId}/process/${billingInfo.productionType}/item-type/${billingInfo.itemType}`)
          .then(options=> {
            setBillOptions(options)
            let newBill = [];
            if (bill && bill.length > 0) {
              newBill = bill.map(x => {
                x.fromDB = true
                x.custId = billingInfo.custId
                return x
              })
            } else {
              const baseCosts = options.filter(billOption =>  {return billOption.costType === 'base'})
              newBill = baseCosts.map(x => {
                delete x.id;
                x.custId = billingInfo.custId
                return x
              })
            }    
            setLineItems(newBill)
          }) 
      })
  }

  useEffect(() => {
    getSupplierBill(billingInfo.orderItemId)
  },[])

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Cost</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          { 
            lineItems && (
              lineItems.map((lineItem, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>
                            <Form.Control as="select" 
                              value={lineItem.label}
                              data-type='type'
                              data-index={index}
                              onChange={handleOnChange}
                            >
                              {
                                billOptions.map(billOption => {
                                  return (
                                    <>
                                      <option key={billOption.label}>{billOption.label}</option>
                                    </>  
                                  )
                                })
                              }
                            </Form.Control>
                      </td>
                      <td>
                        <Form.Control type="number" step="0.25" 
                          value={lineItem.cost}
                          data-index={index}
                          data-type='cost'
                          onChange={handleOnChange}
                        ></Form.Control>
                      </td>
                      <td className="text-center">
                        <span 
                          className="oi oi-circle-x pointer"
                          title="Delete row"
                          data-index={index}
                          onClick={handleOnDeleteClick}
                        ></span>
                      </td>
                    </tr>
                  </>
                )
              })
            )

          }
        </tbody>
        <tfoot>
          <tr>
            <td className="text-right text-bold">Total</td>
            <td className="text-bold">${getTotal()}</td>
            <td></td>
          </tr>

        </tfoot>
      </Table>
      <div className="text-right">
        <Button 
          className="shadow"
          variant="primary" 
          onClick={handleAddLine} 
          className="mr-1"
          disabled={showSuccessAlert ? 'disabled' : false}
        >
          <span className="oi oi-plus"></span> Add Line
        </Button>
        <Button 
          className="shadow"
          variant="success" 
          onClick={handleSave} 
          disabled={showSuccessAlert ? 'disabled' : false}
        >
          <span className="oi oi-check" ></span> Save
        </Button>
        {
          showSuccessAlert && (
            <>
              <Alert className="mt-1" variant="success"  onClose={() => setShowSuccessAlert(false)} dismissible>Bill saved <div><small>(This alert will close automatically</small>)</div></Alert>
            </>
          )
        }
      </div>
    </>
  )
}

export default SupplierBilling