import React, {useState, useEffect} from "react"
import Layout from "../../components/layout"
import {Container, Table, Button, Form, Row, Col } from 'react-bootstrap'
import SEO from "../../components/seo";
import Loader from '../../components/loading';
import DatePickerFilter from '../../components/datepickerFilter';
import constants from '../../helpers/constants';

import * as jwtDecode from 'jwt-decode';
import LocalStorage from "../../components/localStorage";
import Fetch from "../../helpers/fetch";
import { 
  DDMMYYYYFormat, 
  DDMMYYYYFormatWithTime, 
  YYYYMMDDFormat, 
  generateMonths
} from '../../helpers/dateTime';
import StandardModal from '../../components/modal';
import SupplierBilling from '../../components/suppliers/billing';


const Suppliers = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [prodArray, setProdArray] = useState([]);
  const [supplierId, setSupplierId] = useState(0);
  const [dateFilter, setDateFilter] = useState('');
  const [itemTypeFilter, setItemTypeFilter] = useState('');
  const [showBiling, setShowBiling] = useState(false);
  const [allowedViews, setAllowedViews] = useState([]);
  const [month, setMonth] = useState('');
  const [months, setMonths] = useState(generateMonths(3,6));
  
  const [billingInfo, setBillingInfo] = useState({
    orderId: '',
    orderItemId: '',
    productionType: '',
    itemType: '',
    custId: '',
  })

  const handleOnClose = () => {
    setShowBiling(false);
  }

  const getStatus = (item, lastStatus) => {
    return(
       item.status && (
        <>
          {item.status}
          <p><small>{DDMMYYYYFormatWithTime(lastStatus.timestamp)}</small></p>
        </>
      )
    )
  }

  const itemInByField = (item) => {
    if (item.item_in_by) {
      const itemInByObject = JSON.parse(item.item_in_by)
      const itemInByDate = itemInByObject && itemInByObject.changedDate ? itemInByObject.changedDate : itemInByObject.original;
      return  DDMMYYYYFormat(itemInByDate)  
    }
  }
  

  const getConfirmBiling = (item) => {
    if (item.billPaid) {
      return (<></>);
    }

    return ( item.status === 'Collection' && 
      <>
        <Button 
          className="shadow-sm" 
          data-order-item-id={item.id} 
          data-order-id={item.order_id}
          data-production-type={item.production_type}
          data-item-type={item.item_type}
          data-cust-id={item.cust_id}
          onClick={handleOnClick}
        >Confirm Billing</Button>
      </>
    )
  }

  const handleOnClick = (e) => {
    setBillingInfo({
      orderId: e.target.dataset.orderId,
      orderItemId: e.target.dataset.orderItemId,
      productionType: e.target.dataset.productionType,
      itemType: e.target.dataset.itemType,
      custId: e.target.dataset.custId,
      supplierId,
    })
    setShowBiling(true);
  }

  useEffect(() => {
    getAllowedViews();
  },[])

  const getAllowedViews = () => {
    let id = '';
    let username = '';
    if (jwtDecode(LocalStorage.get('jwt')) && jwtDecode(LocalStorage.get('jwt')).id) {
      id = jwtDecode(LocalStorage.get('jwt')).id
      username = jwtDecode(LocalStorage.get('jwt')).username
    }

    if (username) {
      Fetch.get(`${constants.BACKEND_URL}/suppliers/user/${username}`)
      .then(data=> {
        setAllowedViews(JSON.parse(data.allowedViews));
      })
    }
  }

  useEffect(() => {
    getSupplierProdSheet(); 
  }, [supplierId, dateFilter, itemTypeFilter, month])

  const getSupplierProdSheet= (page = 1, limit = 25) => {
    setShowLoader(true);
    if (jwtDecode(LocalStorage.get('jwt')) && jwtDecode(LocalStorage.get('jwt')).username) {
      const supplierLogin = jwtDecode(LocalStorage.get('jwt')).username;
      const pageNumber = page > 0 ? page : 1;
      const offset = (pageNumber-1) * limit
      let queryString = '';
      let queryParams = [];
      let url = `${constants.BACKEND_URL}/order-items/prodsheet/supplier/${supplierLogin}/${offset}/${limit}`
      if (dateFilter) {
        queryParams.push(`dateFilter=${YYYYMMDDFormat(dateFilter)}`)
      }
      if(itemTypeFilter) {
        queryParams.push(`itemTypeFilter=${itemTypeFilter}`)
      }
      if(month) {
        queryParams.push(`month=${month}`)
      }
      if(queryParams.length > 0) {
        queryString = `?${queryParams.join('&')}`
      }

      Fetch.get(`${url}${queryString}`)
      .then((data) => {
        if (data) {
          let array = [...data.data];
          array.forEach((x) => {
            const itemInByObject = JSON.parse(x.item_in_by);
            let itemInByDate = null;
            if (itemInByObject) {
              itemInByDate = itemInByObject.changedDate ? itemInByObject.changedDate : itemInByObject.original;
            }
            if (itemInByDate) {
              x.extractedItemInByDate = new Date(itemInByDate)
            } else {
              x.extractedItemInByDate = new Date(3000,12,31)
            }
          });
          array.sort((a,b) => a.extractedItemInByDate > b.extractedItemInByDate ? 1 : -1 );
  
          setProdArray(array)
        }
        setShowLoader(false);
      })
    }
  }

  const handleDatePickerChange = (e) => {
    setDateFilter(e);
  }

  const handleOnMonthChange = (e) => {
    setMonth(e.target.value);
  }

  const handleOnChangeItemTypeFilter = (e) => {
    setItemTypeFilter(e.target.value)
  }

  const handleOnClearAllFilter = () => {
    setDateFilter('');
    setItemTypeFilter('');
    setMonth('');
  }
  
 
  return (
    <Layout pageInfo={{ pageName: "suppliers" }}>
        <Loader show={showLoader}></Loader>
        <SEO title="Suppliers page" />
        <Container fluid className="text-center">
          <Row>
            <Col className="text-center">
              <h5>Suppliers Production Sheet</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <DatePickerFilter 
                datePickerLabel= {'ItemInBy Filter'} 
                selected={dateFilter}
                handleDatePickerChange={handleDatePickerChange}>
              </DatePickerFilter>
            </Col>
            <Col>
              <Form.Group as={Row} controlId="formGridState">
                <Form.Label column sm={4}>Month</Form.Label>
                <Col sm={8}>
                  <Form.Control as="select" value={month} onChange={handleOnMonthChange}>
                    <option value=''>--Select Month--</option>
                    {
                      months.map(x => {
                        return (
                          <option key={x} >{x}</option>
                        )
                      })
                    }
                  </Form.Control>
                </Col>
                
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm={4} lg={4} className='text-left'>
                  Item Type
                </Form.Label>
                <Col sm={8} lg={8}>
                  <Form.Control as="select" value={itemTypeFilter} onChange={handleOnChangeItemTypeFilter}>
                    <option value='' key=''>--Select Item Type--</option>
                    {
                      allowedViews ?
                      allowedViews.map(view => {
                        return <option key={view}>{view}</option>
                      }) : <></>
                    }
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
              <Col className="text-left" >
                <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a></small>
              </Col>
            </Row>
          <Table responsive>
            <thead>
              <tr>
                <th>Cust ID</th>
                <th>Consultant Name</th>
                <th>FabID/Desc/Cont/Lin</th>
                <th>Item</th>
                <th>Status</th>
                <th>Status Count (days)</th>
                <th>ItemInBy</th>
                <th>Days Left <div>(sorted by urgency)</div></th>
                <th>Billing</th>
              </tr>
            </thead>
            <tbody>
              {prodArray  && Array.isArray(prodArray) ? 
                prodArray.map(item => {
                  const lastStatus = JSON.parse(item.status_change_log);
                  lastStatus.splice(0, lastStatus.length -1);
                  
                  const max90 = new Date().getTime() - new Date(lastStatus[0].timestamp).getTime()
                  const Difference_In_Days = Math.round(max90 / (1000 * 3600 * 24))
                  
                  let daysLeft = 0;
                  if (item.item_in_by) {
                    const itemInByObject = JSON.parse(item.item_in_by)
                    const itemInByDate = itemInByObject && itemInByObject.changedDate ? itemInByObject.changedDate : itemInByObject.original;
                    daysLeft = Math.ceil( (new Date(itemInByDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
                  }
                  return (
                    <tr key={item.id}>
                      <td>{item.cust_id}</td>
                      <td>{item.consultant_name}</td>
                      <td>{item.item_desc}</td>
                      <td>{item.item_type}</td>
                      <td>{getStatus(item, lastStatus[0])}</td>
                      <td>{Difference_In_Days}</td>
                      <td>{itemInByField(item)}</td>
                      <td>{daysLeft}</td>
                      <td>{getConfirmBiling(item)}</td>
                    </tr>
                  )
                }) : <tr></tr>
              }
            </tbody>
          </Table>
        </Container>
        <StandardModal 
          onClose={handleOnClose} 
          show={showBiling}
          title="Confirm Billing"
          body={``}
        >
          <div>Item Type: <strong>{billingInfo.itemType}</strong></div>
          <div>Customer ID: <strong>{billingInfo.custId}</strong></div>
          <div>Please enter any additional cost for this item</div>
          <SupplierBilling 
            billingInfo={billingInfo}
          ></SupplierBilling>
        </StandardModal>
    </Layout>
  )
}

export default Suppliers